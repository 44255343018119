<template>
  <v-container id="user-login" fluid tag="section" fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="5" lg="3">
        <validation-observer ref="observer">
          <base-material-card>
            <template #heading>
              <div class="display-2 font-weight-light">
                {{ $t('login.operator-login') }}
              </div>
            </template>
            <v-form>
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('login.card-no')"
                    >
                      <v-text-field
                        ref="cardNo"
                        v-model="inputs['cardNo']"
                        :disabled="editPin"
                        type="password"
                        class="purple-input"
                        :label="$t('login.card-no')"
                        name="cardNo"
                        :error-messages="errors"
                        @keydown.enter="onCardNoEnter"
                        @keydown.tab="onCardNoTab"
                        @keydown.esc="startOver"
                        @focus="onInputFocus"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('login.pin')"
                    >
                      <v-text-field
                        ref="pin"
                        v-model="inputs['pin']"
                        :disabled="!editPin"
                        class="purple-input"
                        :label="$t('login.pin')"
                        type="password"
                        name="pin"
                        required
                        counter
                        :error-messages="errors"
                        @keydown.enter="submit"
                        @keydown.tab="onCardNoTab"
                        @keydown.esc="startOver"
                        @focus="onInputFocus"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn class="mr-3 accent" @click="startOver">
                      {{ $t('login.start-over') }}
                    </v-btn>
                    <v-btn class="mr-0 accent" @click="submit">
                      {{ $t('login.login') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <SimpleKeyboard
                    :key="keyboardEpoch"
                    :input="inputs[inputName]"
                    :input-name="inputName"
                    :layout="numeric"
                    @onChange="onChange"
                    @onKeyPress="onKeyPress"
                  />
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Users from '@/services/UserService.js';
import SimpleKeyboard from '@/components/keyboard/SimpleKeyboard';
import keyboardMixin from '@/components/keyboard/keyboardMixin';
import numeric from '@/views/operator/components/NumericLayout.js';

export default {
  components: {
    SimpleKeyboard
  },
  mixins: [keyboardMixin],
  data: () => ({
    inputs: {
      cardNo: '',
      pin: ''
    },
    inputName: 'cardNo',
    editPin: false,
    input: '',
    numeric: numeric
  }),
  mounted() {
    this.focusCardNo();
  },
  methods: {
    async submit() {
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          const loginRes = await Users.login(
            this.inputs['cardNo'],
            this.inputs['pin'],
            true
          );
          // console.log(loginRes)
          // let decoded = jwt_decode(loginRes.access);
          // console.log(decoded);
          // const user = await Users.getUser(decoded.user_id)
          console.log('user:', loginRes.user);
          this.$store.commit('LOGIN', {
            accessToken: loginRes.access,
            exp: loginRes.exp,
            refreshToken: loginRes.refresh,
            login: 'Operator',
            user: loginRes.user,
            prefix: '/operator/'
          });

          this.$router.push({ name: 'operator-view' });
        } catch (err) {
          console.error(err);
          await this.$swal({
            title: this.$t('login.operator-login'),
            html: this.$t('login.failed'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          });
          this.startOver();
        }
      }
    },
    onCardNoEnter() {
      if (this.inputs['cardNo'].length == 0) {
        return;
      }
      this.editPin = true;
      this.$nextTick(() => {
        this.focusPin();
      });
    },
    onCardNoTab() {
      if (this.inputs['cardNo'].length == 0) {
        return;
      }
      this.editPin = true;
    },
    startOver() {
      this.inputs['cardNo'] = '';
      this.inputs['pin'] = '';
      this.editPin = false;
      this.$refs.observer.reset();
      this.$nextTick(() => {
        this.focusCardNo();
      });
    },
    focusCardNo() {
      this.$refs.cardNo.focus();
    },
    focusPin() {
      this.$refs.pin.focus();
    },
    onChange(input) {
      console.log('onChange:', input);
      this.inputs[this.inputName] = input;
    },
    onKeyPress(button) {
      switch (button) {
        case '{enter}':
          if (this.inputName == 'cardNo') {
            this.onCardNoEnter();
          } else if (this.inputName == 'pin') {
            this.submit();
          }
          break;
      }

      console.log('button', button);
    },
    onInputFocus(input) {
      console.log('Focused input:', input.target.name);
      this.inputName = input.target.name;
    }
  }
};
</script>
